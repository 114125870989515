<template>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xl-9 py-3 px-0" :class="{'lightgrey_bg': isOdd}">
      <!--DESKTOP-->
      <div class="row m-0 align-items-center d-none d-md-flex font15">
        <div class="col-md text-center font15">
          {{item.zakaatName}}
        </div>
        <div class="col-md-2 text-center font15 text-capitalize">
          {{date}}
        </div>
        <div class="col-md-3 text-center font15 text-capitalize">
          <div v-if="item.zakaatPaid" class="row m-0 align-items-center justify-content-center bold green-text ">
            <div class="col-auto pe-0">
              Donated/Paid
            </div>
            <div class="col-auto px-0">
              <ios-checkmark-icon class="inline-icon big" />
            </div>
          </div>
          <Button color="red" btnText="Donate Now" size="small" icon="arrow" @click="donate" v-else >
            <IconArrowForward size="size16" />
          </Button>
        </div>
        <div class="col-md-3 text-center">
          <div class="row align-items-center justify-content-end">
            <div class="col-auto px-0">
              <Button color="grey" btnText="EDIT" icon="arrow" size="small" @buttonClicked="edit">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto ps-1 pe-0">
              <Button color="green" btnText="VIEW" size="small" @click="view"/>
            </div>
            <div class="col-auto ps-1 pe-0">
              <Button color="darkgrey" icon="arrow" size="xxsmall" @click="print">
                <IconFile size="size12" />
              </Button>
            </div>
            <div class="col-auto ps-1 pe-0">
              <Button color="darkgrey_red" icon="arrow" size="xxsmall" @click="deleteOrder">
                <ios-close-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <!--END DESKTOP-->
      <!--MOBILE-->
      <div class="row mx-0 align-items-center d-md-none position-relative font13">
        <div class="col-3 pe-0 text-truncate">
          {{item.zakaatName}}
        </div>
        <div class="col-3 p-0 text-center">
          {{date}}
        </div>
        <div class="col-3 p-0 text-center">
          <ios-checkmark-circle-outline-icon class="inline-icon big green-text" v-if="item.zakaatPaid" />
          <ios-close-circle-outline-icon v-else  class="inline-icon big red-text" />
        </div>
        <div class="col-3 p-0 text-center">
          <Button color="green" btnText="OPEN" size="small" @click="view"/>
        </div>
      </div>
      <!--END MOBILE-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconFile: defineAsyncComponent(() => import('@/components/icons/IconFile.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-checkmark-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark-circle-outline.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue')),
    'ios-close-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle-outline.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Manage Zakaah Item',
  props: ['item', 'thekey'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      donation: {
        amount: '',
        currency: this.defaultCurrency,
        projectId: null,
        donationTypes: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.spotPriceDate))
      return date.toLocaleString(DateTime.DATE_SHORT)
    },
    isOdd () {
      return this.thekey % 2
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    selectedProject () {
      if (this.isUK) {
        return this.adminProjects.filter(project => {
          if (project.projectID !== 9) {
            return false
          } else {
            return true
          }
        })
      } else {
        return this.adminProjects.filter(project => {
          if (project.projectID !== 28) {
            return false
          } else {
            return true
          }
        })
      }
    }
  },
  methods: {
    ...mapActions(['downloadZakaatSummary', 'addToCart']),
    view () {
      this.$emit('showDetails')
    },
    edit () {
      this.$router.push('/zakaahcalculator/' + this.thekey)
    },
    async print () {
      await this.downloadZakaatSummary(this.item.zakaatDetailID)
    },
    deleteOrder () {
      this.$emit('deleteOrder')
    },
    async donate () {
      await this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.selectedProject[0].projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType.donationType.donationTypeID !== 1) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: dType, amount: this.item.totalZakaat })
        }
      })
      this.donation.projectId = this.selectedProject[0].projectID
      if (!this.donation.currency) {
        this.donation.currency = this.defaultCurrency
      }
      this.donation.amount = this.item.totalZakaat
      this.addToCart(this.donation)
      this.donation = {
        amount: '',
        currency: this.defaultCurrency,
        projectId: null,
        donationTypes: []
      }
    }
  }
}
</script>

<style scoped>
</style>
